@import "variables";
@import "core/fonts";

.not-found {
  height: 100vh;
  padding: 0;
  margin: 0;
  background: url("../assets/404-bg.png") repeat-x;
  background-size: auto 100%;

  * {
    box-sizing: border-box;
  }

  > div {
    display: flex;
  }

  &__left, &__right {
    flex: 1;
    padding: 30px;
  }

  &__left {
    h1 {
      font-family: $font-family-base;
      font-size: 15em;
      margin: 0;
    }
  }

  &__right {
    display: flex;
    align-items: center;

    img {
      height: 90vh;
    }
  }
}
